import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import { IndexQueryQuery, PostByPathQuery } from '../../../types/graphql-types'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const PrivacyPage: React.FC<Props> = ({ data, location }: Props) => {
  return (
    <Layout location={location}>
    	<Meta title="404: Not found" />
    	<section>
    		<h1 className="text-center">404: Not Found</h1>
    		<p className="text-center">You just hit a route that doesn&#39;t exist... the sadness.</p>
    	</section>
    </Layout>
  )
}

export default PrivacyPage
